function convertAnimation(startText, endText, elementId) {
  const exampleElement = document.getElementById(elementId);
  const newElement = exampleElement.cloneNode(true);
  newElement.textContent = startText;
  void newElement.offsetWidth;
  newElement.classList.add('run-animation');
  setTimeout(() => {
    newElement.textContent = endText;
    setTimeout(convertAnimation, 10000, startText, endText, elementId);
    newElement.classList.remove('run-animation');
  }, 5000);
  exampleElement.parentNode.replaceChild(newElement, exampleElement);
}

convertAnimation(
  'R$ 23,45',
  'R$ 23,45 (twenty three brazilian reais and forty five cents)',
  'convert-example'
);
convertAnimation(
  'US$ 1,01',
  'US$ 1,01 (one dollar and one cent)',
  'convert-example-2'
);
